<script>
  import BaseIcon from '@/components/base/BaseIcon'

  /**
   * An input icon, which can optionally be a button or a link. Should only be used in the `icons` slot of a `BaseInput`.
   */
  export default {
    extends: BaseIcon,
    inject: {
      input: {
        from: 'BaseInput',
        default: undefined,
      },
    },
    props: {
      /** Only if `type` is `button` or `link`. Whether the button or link is disabled. Note that if the input that contains this icon is disabled, the icon will automatically be disabled regardless of this prop. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** The size of the icon, in Tailwind units. Should not be higher than 8. */
      size: {
        type: Number,
        default: 6,
        validator: (value) => value >= 1 && value <= 8,
      },
      /** The color of the icon. */
      color: {
        type: String,
        default: 'gray',
        validator: (value) =>
          [
            'gray',
            'primary',
            'red',
            'black',
            'white',
            'green',
            'primary-dark',
            'white',
            'summer',
          ].includes(value),
      },
    },
    computed: {
      inputDisabled() {
        return this.input?.disabled ?? false
      },
      finalDisabled() {
        return this.disabled || this.inputDisabled
      },
      colorClasses() {
        if (this.finalDisabled) {
          return `text-gray ${!this.inputDisabled ? 'text-opacity-50' : ''}`
        }
        switch (this.color) {
          case 'gray':
            return `text-gray ${
              this.clickable ? 'hover:text-gray-darker active:text-gray-darkest' : ''
            }`
          case 'primary':
            return `text-primary ${
              this.clickable ? 'hover:text-primary-bright active:text-primary-dark' : ''
            }`
          case 'red':
            return 'text-red'
          case 'black':
            return 'text-black'
          case 'green':
            return 'text-green'
          case 'primary-dark':
            return 'text-primary-dark'
          case 'white':
            return 'text-white'
          case 'summer':
            return 'text-summer'
          default:
            return ''
        }
      },
      propsToPassDown() {
        const { disabled, size, color, ...propsToPassDown } = this.$props
        propsToPassDown.disabled = this.finalDisabled
        return propsToPassDown
      },
    },
  }
</script>

<template>
  <BaseIcon
    :class="`flex justify-center items-center w-8 h-8 rounded-sm ${colorClasses} ${
      clickable ? 'pointer-events-auto' : ''
    }`"
    v-bind="{ ...propsToPassDown, ...$attrs }"
    v-on="$listeners"
  >
    <span class="static" :style="sizeStyle">
      <!-- @slot The icon. Should contain an SVG icon component. -->
      <slot />
    </span>
  </BaseIcon>
</template>
