export default {
  props: {
    /** The size in Tailwind units. */
    size: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    sizeStyle() {
      if (!this.size) {
        return {}
      }
      return {
        width: this.$twToRem(this.size),
        height: this.$twToRem(this.size),
      }
    },
  },
}
