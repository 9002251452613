<script>
  import linkMixin from '@/mixins/linkMixin'
  import sizeMixin from '@/mixins/sizeMixin'
  import env from '@/helpers/env'

  /**
   * A generic icon component, which can optionally be a button or a link.
   */
  export default {
    mixins: [linkMixin, sizeMixin],
    props: {
      /** The type of component this is. When left `undefined`, the type is determined automatically based on the presence of link props (`to` or `href`) or a `click` event listener. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['icon', 'button', 'link'].includes(value),
      },
      /** The hidden label of the icon, for screen readers. Required for types `button` and `link`. */
      label: {
        type: String,
        default: undefined,
      },
      /** Only if `type` is `button` or `link`. Whether the button or link is disabled. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** The size of the icon, in Tailwind units. By default it is undefined, meaning it fills its container’s width. */
      size: {
        type: Number,
        default: undefined,
      },
      /** Whether to set the `stroke` property to the current color instead of the `fill` property. */
      stroke: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      finalType() {
        if (this.type === undefined) {
          if (this.hasLink) {
            return 'link'
          } else if (this.$listeners?.click) {
            return 'button'
          } else {
            return 'icon'
          }
        }
        return this.type
      },
      component() {
        switch (this.finalType) {
          case 'button':
            return 'button'
          case 'link':
            return 'BaseLink'
          default:
            return 'span'
        }
      },
      finalDisabled() {
        return this.disabled
      },
      clickable() {
        if (this.finalDisabled) {
          return false
        }
        return this.finalType === 'button' || (this.finalType === 'link' && this.hasLink)
      },
      propsToPassDown() {
        switch (this.finalType) {
          case 'button':
            return {
              type: 'button',
              'aria-label': this.label,
              disabled: this.disabled,
            }
          case 'link':
            if (this.disabled) {
              return {}
            }
            return {
              ...this.linkProps,
              'aria-label': this.label,
            }
          default:
            if (this.label) {
              return {
                role: 'img',
                'aria-label': this.label,
              }
            }
            return {}
        }
      },
    },
    created() {
      this.validateProps()
    },
    updated() {
      this.validateProps()
    },
    methods: {
      validateProps() {
        if (!env.isDev()) {
          return
        }
        if (['button', 'link'].includes(this.finalType) && !this.label) {
          // eslint-disable-next-line no-console
          console.error(
            'Invalid prop: a `<BaseIcon>` of type `button` or `link` must have a `label` prop.'
          )
        }
      },
    },
  }
</script>

<template>
  <component
    :is="component"
    :class="`default-block ${stroke ? 'fill-none stroke-current' : 'fill-current'} ${
      disabled ? 'cursor-default' : ''
    }`"
    :style="sizeStyle"
    v-bind="{ ...propsToPassDown, ...$attrs }"
    v-on="$listeners"
  >
    <!-- @slot The icon. Should contain an SVG icon component. -->
    <slot />
  </component>
</template>

<style lang="postcss">
  /* Using `:where` to allow overriding the styles below with any selector (`:where` has 0 specificity) */
  /* (Not putting this in a `scoped` block because it adds a `[data-v-*]` selector which boosts specificity) */
  /* purgecss start ignore */
  :where(.default-block) {
    @apply block;
  }
  /* purgecss end ignore */
</style>
